<template>
  <div class="links fill-height">
    <v-main
      id="main"
      class="tab-section fill-height"
      data-up="sub-header"
      data-left="categories"
      data-right="filters"
      :style="`padding-top:${headerHeight}px`"
    >
      <div class="content-center">
        <div>

          <h2 class="display-1 black--text mb-6">
            Enllaços
          </h2>

          <ul class="list">
            <li>
              <btn href="https://dogc.gencat.cat/ca/" color="primary" target="_blank" text>
                <span class="text-none">
                  <strong>DOGC:</strong> Diari oficial de la Generalitat de Catalunya
                </span>
              </btn>
            </li>
            <li>
              <btn href="https://www.boe.es/diario_boe/index.php?lang=ca" color="primary" target="_blank" text>
                <span class="text-none">
                  <strong>BOE:</strong> Bulletí oficial de l'Estat
                </span>
              </btn>
            </li>
            <li>
              <btn href="https://eur-lex.europa.eu/oj/direct-access.html?locale=es" color="primary" target="_blank" text>
                <span class="text-none">
                  <strong>DOUE:</strong> Diari oficial de la Unió Europea
                </span>
              </btn>
            </li>
          </ul>

        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import Btn from '@/components/Btn';
import { mapState } from 'vuex';

export default {
  name: 'Links',
  components: { Btn },
  computed: mapState( 'app', [ 'headerHeight' ])
}
</script>
